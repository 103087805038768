//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonAction from '@/shared/components/ButtonAction.vue';
import FCImage from '~/components/FCImage.vue';
import { windowResizeMixin } from '~/mixins/resize';
import { windowMatchMediaMixin } from '~/mixins/matchMedia';
import { AffirmLogo } from '@fc/angie-ui';

export default {
  name: 'Financing_AboveTheFold',
  mixins: [windowResizeMixin, windowMatchMediaMixin],
  components: {
    ButtonAction,
    AffirmLogo,
    FCImage,
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    imageDimensions() {
      const ratio = this.mixinMatchMedia.isDesktop ? 928 / 696 : 638 / 478;
      const maxWidth = this.mixinMatchMedia.isDesktop ? 928 : 638;
      const width = Math.min(this.mixinResize.initialWidth, maxWidth);
      const height = Math.ceil(width / ratio);

      return {
        width,
        height,
      };
    },
  },
  data: () => ({
    title_line_1: 'Buy Now,',
    title_line_2: 'Pay Later',
  }),
};
